/* add css module styles here (optional) */

._3ybTi {
  margin: 2em;
  padding: 0.5em;
  border: 2px solid #000;
  font-size: 2em;
  text-align: center;
}

:root {
    --head-color: blue;
    --background: gray;
    --fillColor: red;
    --fillSecondry: red;
    --changeColorPosition: 10%;
    --width: 100%;
    --headShadowColor: red;
    --headShadowSize: -6px
}

.vertical {
    margin-right: 15px;
    padding-left: 0px;
    padding-right: 0px;
    width: var(--width);
    height: 7px;
    background: var(--background);
    border-radius: 5px;
    background-image: linear-gradient(12deg, var(--fillColor), var(--fillSecondry));
    background-size: var(--changeColorPosition) 100%;
    background-repeat: no-repeat;
}

.vertical::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--head-color);
    cursor: ns-resize;
    box-shadow: 0 0 20px var(--headShadowSize) var(--headShadowColor);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    background: white;
}

.horizontal::-webkit-slider-thumb {
  cursor: ew-resize;
}

.vertical::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

.thumb {
  /* width: 5px;
  height: 10px;
  border-radius: 2px; */
  margin-top: 2px;
  cursor: pointer;
}

.track {
  height: 4px;
  background: gray;
}

.range-slider {
  height: 4px !important;
}

.range-slider__thumb {
  width: 10px !important;
  height: 15px !important;
}
