input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1
}

input[type="time"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}

input[type="time"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1
}

textarea {
  resize: none;
}

a {
  text-decoration: underline;
  color: blue;
  text-decoration-color: blue;
}

.npm__react-simple-code-editor__textarea {
  height: 1000px !important;
}
